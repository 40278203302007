var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative", attrs: { id: "map-search" } },
    [
      _c("Map", {
        attrs: { isSearchCtlShow: true, isfullscreenControlShow: false }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }