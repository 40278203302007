<template lang="pug">
  div#map-search.position-relative
    Map(:isSearchCtlShow="true",:isfullscreenControlShow="false")
</template>

<script>
import Map from '@/components/Map.vue'
export default {
  // コンポーネント
  components: {
    Map
  }
}
</script>

<style lang="stylus" scoped>
  #map-search
    min-height calc(100vh - 255px)
</style>